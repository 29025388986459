import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

// Components
import ArrowIcon from "../icons/ArrowIcon"

// Types
import { IBox } from "./BoxesBlock"

const TextBox = ({ title, link_to_internal, background, color }: IBox) => (
  <BlockBox backDrop={background} color={color}>
    {title.text !== null && <Title>{title.text}</Title>}
    {link_to_internal.text !== null && (
      <Link to={link_to_internal.text}>
        <ArrowIcon color={colors.white} />
      </Link>
    )}
  </BlockBox>
)

export default TextBox

interface IBoxStyling {
  backDrop: string
  color: string
}

const BlockBox = styled.div<IBoxStyling>`
  width: calc(100% - 60px);
  max-width: 100%;
  min-height: 150px;
  margin: 10px 0;
  flex-direction: column;
  padding: 30px;
  overflow: hidden;
  background-color: ${props =>
    props.backDrop ? props.backDrop : colors.black};
  color: ${props => (props.color ? props.color : colors.white)};

  & a {
    width: 30px;
  }

  ${mq.from.XS`
    margin: 25px;
    width: calc(50% - 85px);

    &:nth-child(even) {
      margin-right: 0;
    }
    &:nth-child(odd) {
      margin-left: 0;
    }
  `}

  ${mq.from.L`
    &:nth-child(n) {
      margin: 25px;
      width: calc(33.33% - 93.33px);
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  `}

  /* All corner curves */
  &:nth-child(1) {
    border-radius: 0 50px 0 0;
  }

  &:nth-child(2) {
    border-radius: 0 0 50px 0;
  }

  &:nth-child(7) {
    border-radius: 0 0 0 50px;
  }

  &:nth-child(12) {
    border-radius: 0 0 50px 0;
  }
`

const Title = styled.h3`
  font-size: 30px;
  margin: auto;
  word-break: break-word;

  ${mq.from.M`
    margin: 1em 0;
    line-height: 0.9;
    font-size: 50px;
  `}
`
