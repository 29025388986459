import React from "react"
import styled from "styled-components"

// Styling
import colors from "../../styles/colors"
import { mq } from "../../styles/utils/mq"

// Types
import { IImage } from "../../types"

interface ISlogan {
  slogan: string
  image: IImage
}

const Slogan = ({ slogan, image }: ISlogan) => (
  <BlockBox>
    <Indexer>{slogan}</Indexer>
    <Image src={image.url} alt={image.alt} />
  </BlockBox>
)

export default Slogan

const BlockBox = styled.div`
  display: block;
  background-color: ${colors.black};
  flex-wrap: wrap;
  max-width: 100%;
  flex-direction: column;
  width: 80vw;
  padding: 0px 10vw 25px 10vw;
  color: ${colors.white};
  text-align: center;

  ${mq.from.S`
    flex-direction: row;
  `};
  ${mq.from.M`
    display: flex;
  `};
`

const Indexer = styled.div`
  width: 100%;
  font-size: 35px;
  text-align: center;
  display: block;
  line-height: 2;
  font-weight: bolder;

  ${mq.from.XS`
  font-size: 45px;
  `}

  ${mq.from.S`
  font-size: 55px;
  `}

  ${mq.from.M`
  font-size: 65px;
    width: 75%;
    text-align: left;
  `}

  ${mq.from.L`
  font-size: 75px;
    width: 75%;
    text-align: left;
  `}

  ${mq.from.XL`
  font-size: 85px;
    width: 60%;
  `}
`

const Image = styled.img`
  width: 100%;
  margin: auto 0;
  height: auto;
  object-fit: cover;
  object-position: center;

  ${mq.from.XS`
    width: 80%;
  `}

  ${mq.from.S`
    width: 70%;
  `}

  ${mq.from.M`
    width: 25%;
  `}

  ${mq.from.XL`
    width: 40%;
  `}
`
