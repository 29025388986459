import React from "react"
import styled from "styled-components"

// Components
import ImageBox from "./ImageBox"
import TextBox from "./TextBox"

// Styling
import { mq } from "../styles/utils/mq"
import { IImage } from "../types"

interface IBoxes {
  boxes: IBox[]
}

export interface IBox {
  color: string
  background: string
  image: IImage
  link_to_internal: {
    text: string
  }
  title: {
    text: string
  }
}

const BoxesBlock = ({ boxes }: IBoxes) => (
  <Boxes>
    {boxes.map((block: IBox, index: number) => {
      if (block.image.url !== null) {
        return (
          <ImageBox key={index} src={block.image.url} alt={block.image.alt} />
        )
      } else {
        return (
          <TextBox
            key={index}
            color={block.color}
            background={block.background}
            image={block.image}
            link_to_internal={block.link_to_internal}
            title={block.title}
          />
        )
      }
    })}
  </Boxes>
)

export default BoxesBlock

const Boxes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 100px;

  div {
    display: flex;
  }

  ${mq.from.XS`
    flex-direction: row;
  `}
`
