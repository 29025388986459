import React from "react"
import styled from "styled-components"

// Styling
import colors from "../../styles/colors"
import { mq } from "../../styles/utils/mq"

interface IBlock {
  index: number
  title: string
}

const BlockTitle = ({ index, title }: IBlock) => (
  <BlockBox>
    <Indexer>&#8212; {("0" + index).slice(-2)}</Indexer>
    <Title>{title}</Title>
  </BlockBox>
)

export default BlockTitle

const BlockBox = styled.div`
  display: flex;
  padding: 5px 0;
  border-top: 1px solid ${colors.black};
  flex-direction: column;
  color: ${colors.black};
  font-size: 20px;
  font-weight: bolder;

  ${mq.from.M`
    font-size: 30px;
  `}

  ${mq.from.L`
    line-height: 1;
  `}
`

const Indexer = styled.div`
  display: block;
`

const Title = styled.div`
  display: block;
`
