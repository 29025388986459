import React from "react"
import styled from "styled-components"

// Styles
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

interface IProps {
  text: {
    __html: string
  }
  image: {
    url: string
    alt: string
  }
}

const ContentBlock: React.FC<IProps> = ({ text, image }) => (
  <Container>
    {image.url !== null && (
      <ContainerBlock>
        <Image src={image.url} alt={image.alt} />
      </ContainerBlock>
    )}
    <ContainerBlock>
      {text.__html !== null && <TextBlock dangerouslySetInnerHTML={text} />}
    </ContainerBlock>
  </Container>
)

export default ContentBlock

export const Container = styled.div`
  display: block;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 84px auto;
  flex-direction: column;

  ${mq.from.S`
    flex-direction: row;
  `}

  ${mq.from.M`
    display: flex;
    margin: 100px auto;
  `}
`

export const ContainerBlock = styled.div`
  margin: auto;
  overflow: hidden;
  flex: 50%;
  font-size: 22px;
`

export const TextBlock = styled.div`
  margin: 25px 0;
  text-align: left;
  color: ${colors.black};
  font-size: 22px;

  ul {
    color: ${colors.black};
    padding-left: 20px;
    margin-left: 15px;

    li {
      margin: 10px 0;
    }
  }
`

const Image = styled.img`
  width: 100%;
  margin: 5% 0;
  padding: 0;
  height: auto;
  object-fit: cover;
  object-position: center;

  ${mq.from.S`
    width: 80%;
    margin: 5% 10%;
  `}

  ${mq.from.M`
    width: 90%;
    margin: 0 5%;
  `}

  ${mq.from.L`
    width: 80%;
    margin: 0 10%;
  `}
`
