import React from "react"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

// Types
import { IImage } from "../types"

interface IGrid {
  text: {
    __html: string
  }
  images: IImage[]
}

const GridText = ({ text, images }: IGrid) => {
  if (images.length > 3) {
    return null
  } else {
    return (
      <>
        <GridBox>
          <TextBox dangerouslySetInnerHTML={text} />
        </GridBox>
        <Wrapper>
          {images.map((image, index) => (
            <Item key={index}>
              <ImageWrapper>
                {image.url && <ItemImage src={image.url} alt={image.alt} />}
              </ImageWrapper>
            </Item>
          ))}
        </Wrapper>
      </>
    )
  }
}

export default GridText

const GridBox = styled.div`
  background-color: ${colors.black};
  padding: 100px 0;
`

const Wrapper = styled.div`
  width: 100%;
  margin-top: -200px;
  ${mq.from.M`
    display: flex;
  `}

  flex-flow: row wrap;
  align-items: stretch;
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
`

const Item = styled.div`
  position: relative;
  padding-bottom: 20px;
  line-height: 0;
  margin: 50px 0;

  &:nth-child(1) {
    flex: 0 21.49%;
    margin-bottom: auto;
    margin-top: 0;
  }

  &:nth-child(2) {
    flex: 0 21.49%;
    margin: auto 0 50px 0;
  }

  &:nth-child(3) {
    flex: 0 57.02%;
  }

  &:not(:last-of-type) {
    margin-bottom: 75px;
  }

  ${mq.from.S`
    margin-bottom: 0;
    &:nth-child(1) {
      flex: 0 25%;
      margin-bottom: auto;
      margin-top: 0;
    }

    &:nth-child(2) {
      flex: 0 25%;
      margin: auto 0 50px 0;
    }

    &:nth-child(3) {
      flex: 0 50%;
    }
  `}

  ${mq.from.M`
    &:nth-child(1) {
      div div {
        top: -50px;
      }
    }

    &:nth-child(2) {
      div div {
        top: -50px;
      }
    }

    &:nth-child(3) {
      margin: auto 0 50px 0;
      div div {
        top: -50px;
      }
    }
  `}

  ${mq.from.L`
    &:nth-child(1) {
      flex: 0 21.49%;
      margin-bottom: auto;
      margin-top: 0;
    }

    &:nth-child(2) {
      flex: 0 21.49%;
      margin: auto 0 50px 0;
    }

    &:nth-child(3) {
      flex: 0 57.02%;
    }
  `}
`

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  max-height: 400px;
`

const TextBox = styled.div`
  width: 90%;
  padding: 0 5%;
  padding-bottom: 150px;
  color: ${colors.white};
  margin: auto;

  ${mq.from.S`
    padding: 0 25px;
    padding-bottom: 200px;
    border-left: 1px solid ${colors.white};
  `}

  ${mq.from.M`
    padding-bottom: 200px;
  `}

  ${mq.from.M`
    width: 80%;
  `}

  ${mq.from.L`
    width: 65%;
    font-size: 22px;
  `}

  ${mq.from.XL`
    font-size: 25px;
    line-height: 1.3;
  `}
`
