import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Components
import GridText from "../components/GridText"
import PageLayout from "../components/PageLayout"
import ContentBlock, {
  ContainerBlock,
  TextBlock,
} from "../components/ContentBlock"

// Types
import { IImage } from "../types"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

// Data
import BlockTitle from "../components/atoms/BlockTitle"
import BoxesBlock from "../components/BoxesBlock"
import TextImage from "../components/TextImage"
import Slogan from "../components/atoms/Slogan"

export interface IPrinciple {
  name: string
  description: string
  image: IImage
  levels: [{ level: string; description: string; image: IImage }]
}

const Homepage = (prismicData: any) => {
  const imageGrid =
    prismicData.data.allPrismicHomepageBodyImagesGrid.nodes[0].primary
  const hero = prismicData.data.allPrismicHomepage.nodes[0].data
  const textImage = prismicData.data.allPrismicHomepageBodyTextImage.nodes
  const boxesGrid = prismicData.data.allPrismicHomepageBodyBoxesGrid.nodes[0]
  const outro =
    prismicData.data.allPrismicHomepageBodyOutroBlock.nodes[0].primary

  return (
    <PageLayout seoTitle={"Jungle Formula"}>
      <FullWrapper>
        <HeroContainer>
          <ContainerBlock>
            <PageTitle>{hero.hero_text.text}</PageTitle>
            <UnderTitle>{hero.hero_sub_title.text}</UnderTitle>
          </ContainerBlock>
          <ContainerBlock>
            <img src={hero.hero_image.url} alt={hero.hero_image.alt} />
          </ContainerBlock>
        </HeroContainer>

        <GridText
          text={{ __html: imageGrid.text.html }}
          images={[
            imageGrid.small_image,
            imageGrid.second_small_image,
            imageGrid.large_image,
          ]}
        />
      </FullWrapper>

      <BlockTitle
        index={textImage[0].primary.number1}
        title={textImage[0].primary.title.text}
      />

      <ContentBlock
        text={{ __html: textImage[0].primary.text.html }}
        image={textImage[0].primary.image}
      />

      <BlockTitle
        index={textImage[1].primary.number1}
        title={textImage[1].primary.title.text}
      />

      <ContentBlock
        text={{ __html: textImage[1].primary.text.html }}
        image={textImage[1].primary.image}
      />

      <BlockTitle
        index={boxesGrid.primary.number1}
        title={boxesGrid.primary.title.text}
      />

      <TextBlock
        dangerouslySetInnerHTML={{ __html: boxesGrid.primary.text.html }}
      />

      <BoxesBlock boxes={boxesGrid.items} />

      <FullWrapper>
        <TextImage
          title={outro.title.text}
          text={outro.text.text}
          link={outro.link_to_internal_page.text}
          label={{ __html: outro.link_label.html }}
          image={outro.image}
        />
        <Slogan slogan={outro.slogan.text} image={outro.small_image} />
      </FullWrapper>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    allPrismicHomepageBodyTextImage {
      nodes {
        primary {
          image {
            alt
            url
          }
          number1
          text {
            html
          }
          title {
            text
          }
        }
      }
    }
    allPrismicHomepageBodyOutroBlock {
      nodes {
        primary {
          image {
            alt
            url
          }
          link_to_internal_page {
            text
          }
          text {
            text
          }
          title {
            text
          }
          link_label {
            html
          }
          slogan {
            text
          }
          small_image {
            url
            alt
          }
        }
      }
    }
    allPrismicHomepageBodyImagesGrid {
      nodes {
        primary {
          text {
            html
          }
          large_image {
            url
            alt
          }
          second_small_image {
            alt
            url
          }
          small_image {
            alt
            url
          }
        }
      }
    }
    allPrismicHomepageBodyBoxesGrid {
      nodes {
        primary {
          title {
            text
          }
          number1
          text {
            html
          }
        }
        items {
          color
          background
          image {
            url
            alt
          }
          link_to_internal {
            text
          }
          title {
            text
          }
        }
      }
    }
    allPrismicHomepage {
      nodes {
        data {
          hero_sub_title {
            text
          }
          hero_text {
            text
          }
          hero_image {
            alt
            url
          }
        }
      }
    }
  }
`

export default Homepage

export const FullWrapper = styled.div`
  width: 100vw;
  margin: 0 -5vw;

  ${mq.from.L`
    margin: 0 -10vw;
    width: auto;
  `}
`

const PageTitle = styled.h1`
  color: ${colors.black};
  font-size: 55px;
  line-height: 0.9;

  ${mq.from.XL`
    font-size: 70px;
  `}
`

const UnderTitle = styled(TextBlock)`
  font-size: 25px;
`
const HeroContainer = styled.div`
  display: flex;
  margin: 0;

  ${mq.from.L`
    margin-top: -115px;
  `}

  ${ContainerBlock} {
    width: 50%;
    padding: 0 25px;

    ${mq.from.M`
      padding: 0 75px;
    `}

    &:nth-child(2) {
      padding: 0;
      margin: 0;
      display: none;
      text-align: right;

      img {
        height: 100%;
        object-position: center;
        width: 70%;
        margin: 0;
        max-height: 900px;
      }
    }

    ${mq.from.L`
      &:nth-child(2) {
        display: block;
      }
    `}
  }
`
