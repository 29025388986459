import React from "react"

// Styling
import colors from "../styles/colors"

const ArrowIcon: React.FC<{ color: string }> = ({
  color = colors.black,
  ...props
}) => (
  <svg {...props} viewBox="0 0 40 36" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <path d="M22 0l18 18-18 18-6-6 7-7H0V13h23l-7-7 6-6z" fill={color} />
    </g>
  </svg>
)

export default ArrowIcon
