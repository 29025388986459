import React from "react"
import styled from "styled-components"

// Styles
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

// Components
import ArrowIcon from "../icons/ArrowIcon"

// Types
import { IImage } from "../types"

interface IProps {
  title: string
  text: string
  link: string
  label: {
    __html: string
  }
  image: IImage
}

const TextImage: React.FC<IProps> = ({ text, image, title, link, label }) => (
  <Container>
    <ContainerBlock>
      <Title>{title}</Title>
      {text !== null && <Text>{text}</Text>}
      {link !== null && (
        <ExternalLink href={link}>
          <ArrowIcon color={colors.white} />{" "}
          <span dangerouslySetInnerHTML={label} />
        </ExternalLink>
      )}
    </ContainerBlock>
    {image.url !== null && (
      <ContainerBlock>
        <Image src={image.url} alt={image.alt} />
      </ContainerBlock>
    )}
  </Container>
)

export default TextImage

const Container = styled.div`
  display: block;
  background-color: ${colors.black};
  flex-wrap: wrap;
  max-width: 100%;
  flex-direction: column;
  width: 80vw;
  padding: 75px 10vw;
  color: ${colors.white};
  ${mq.from.S`
    flex-direction: row;
  `};
  ${mq.from.M`
    display: flex;
  `};
`

const ExternalLink = styled.a`
  font-size: 20px;
  font-weight: bold;
  vertical-align: middle;
  color: ${colors.white};
  transition: 0.5s;

  p {
    display: inline-block;
  }

  &:hover {
    color: ${colors.accent1};
    text-decoration: none;
  }

  svg {
    width: 20px;
  }
`

const ContainerBlock = styled.div`
  margin: auto;
  overflow: hidden;
  flex: 50%;
  font-size: 22px;
`

const Text = styled.p`
  margin: 25px 0;
  text-align: left;
  color: ${colors.white};
`

const Title = styled.h3`
  font-size: 35px;
  line-height: 1;
`

const Image = styled.img`
  width: 100%;
  margin: 5% 0;
  padding: 25px 0;
  height: auto;
  object-fit: cover;
  object-position: center;

  ${mq.from.S`
    width: 80%;
    margin: 5% 10%;
  `}

  ${mq.from.M`
    width: 90%;
    margin: 0 5%;
  `}

  ${mq.from.L`
    width: 80%;
    margin: 0 10%;
  `}
`
