import React from "react"
import styled from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"

interface IBox {
  src: string
  alt: string
}

const ImageBox = ({ src, alt }: IBox) => <Image src={src} alt={alt} />

export default ImageBox

const Image = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
  object-position: center;
  object-fit: cover;
  overflow: hidden;

  ${mq.from.XS`
    margin: 25px;
    width: calc(50% - 25px);

    &:nth-child(even) {
      margin-right: 0;
    }
    &:nth-child(odd) {
      margin-left: 0;
    }
  `}

  ${mq.from.L`
    &:nth-child(n) {
      width: calc(33.33% - 33.33px);
      margin: 25px;
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  `}
`
